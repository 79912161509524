import React, { Component } from 'react';
import DesigningWithUniform from './partials/_DesigningWithUniform';
import Introduction from './partials/_Introduction';
import NumberOne from './partials/_NumberOne';
import NumberTwo from './partials/_NumberTwo';
import NumberThree from './partials/_NumberThree';
import FigmaDesign from './partials/_FigmaDesign';
import {
  IconDesign,
  IconFigma,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../components';
import { setDesignCodePreference } from '../../utility/js/designCodePreference';
import styles from './index.module.scss';

export default class GetStartedDesignIndex extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setDesignCodePreference('design');
  }

  render() {
    return (
      <PageWithSubNav pageType="design" title="Get Started" designCode>
        <Section>
          <div className={styles.mainTitle}>
            <IconDesign className={styles.iconDesign} />
            <DesigningWithUniform />
          </div>
        </Section>

        <Section title="Introduction">
          <Introduction role="design" />
        </Section>
        <Section title="Figma Library">
          <div className={styles.gridWrapper}>
            <div>
              <Paragraph>
                Once you’re added to the Hudl Figma Team, enable the Uniform
                Library to get started.
              </Paragraph>
              <Paragraph>
                With Figma you can easily insert any component and know it
                follows Hudl’s design standards—just follow the instructions
                below.
              </Paragraph>
            </div>

            <div className={styles.imageAside}>
              <FigmaDesign />
            </div>
          </div>
        </Section>

        <div className={styles.splitter}>
          <IconFigma className={styles.splitterSketch} />
        </div>

        <div>
          <div className={styles.step}>
            <NumberOne className={styles.stepNumber} />
            Enable the Library
          </div>

          <Paragraph>
            In the <strong>Assets</strong> panel, click the{' '}
            <strong>Team Library</strong> icon and enable the Uniform Library.
          </Paragraph>

          <div className={styles.gridWrapper}>
            <div className={styles.subStep}>
              <img
                src={require('../../imgs/get-started/enable-figma-library-a.png')}
                className={styles.image}
              />
            </div>

            <div className={styles.subStep}>
              <img
                src={require('../../imgs/get-started/enable-figma-library-b.png')}
                className={styles.image}
              />
            </div>
          </div>
          <br />
          <List type="unordered">
            <li>
              If you need access to the Hudl Figma Team, ask for help in #design
              on Slack.
            </li>
          </List>
        </div>

        <div className={styles.splitter}>
          <IconFigma className={styles.splitterSketch} />
        </div>

        <div>
          <div className={styles.step}>
            <NumberTwo className={styles.stepNumber} /> View the Library
          </div>

          <div className={styles.gridWrapper}>
            <div>
              <Paragraph>
                Viewing everything in the library can be done either through{' '}
                <strong>list</strong> or <strong>grid view</strong>. Toggle
                between the two by clicking the icon next to the{' '}
                <strong>Team Library</strong> icon.
              </Paragraph>

              <Paragraph>
                In both cases, hovering over a component will provide the
                description of intended use and tags for search.
              </Paragraph>
              <List type="unordered">
                <li>
                  Additional tags have been provided for fuzzy search to quickly
                  and easily find the component you’re looking for. For example,
                  searching for “trash” will bring up the Delete icon.
                </li>
              </List>
            </div>

            <div className={styles.subStep}>
              <img
                src={require('../../imgs/get-started/view-figma-library.gif')}
                className={styles.image}
              />
            </div>
          </div>
        </div>
        <div className={styles.splitter}>
          <IconFigma className={styles.splitterSketch} />
        </div>

        <div>
          <div className={styles.step}>
            <NumberThree className={styles.stepNumber} /> Use the Library
          </div>

          <div className={styles.gridWrapper}>
            <div>
              <Paragraph>
                To use any component, drag and drop it onto the canvas. Change
                any of the component features using the layers in the{' '}
                <strong>Layers</strong> list.
              </Paragraph>

              <Paragraph>
                In both cases, hovering over a component will provide the
                description of intended use and tags for search.
              </Paragraph>
              <List type="unordered">
                <li>
                  To change a component to the dark environment colors, select
                  the layer and change the fill or text color style to the
                  equivalent dark environment color.
                </li>
              </List>
            </div>

            <div className={styles.subStep}>
              <img
                src={require('../../imgs/get-started/use-the-figma-library.png')}
                className={styles.image}
              />
            </div>
          </div>
        </div>
      </PageWithSubNav>
    );
  }
}
