import React from 'react';
import { IconCode, IconDesign, Link, Paragraph } from '../../../components';
import DeviceIllustration from './_DeviceIllustration';
import styles from '../index.module.scss';

const Introduction = ({ role }) => {
  return (
    <div className={styles.gridWrapper}>
      <div>
        <Paragraph>
          Uniform is Hudl’s design system. It exists to unify Hudl’s products
          through design (<IconDesign className={styles.inlineIcon} />) and code
          (<IconCode className={styles.inlineIcon} />) implementation.
        </Paragraph>
        <Paragraph>
          The system includes{' '}
          <Link href="/components" isDesignCodeLink>
            components
          </Link>
          ,{' '}
          <Link href="/guidelines" isDesignCodeLink>
            visual guidelines
          </Link>
          , <Link href="https://www.hudl.com/design/writing">language</Link> and
          additional <Link href="/resources">resources</Link> to help you build
          more cohesive product interfaces.
        </Paragraph>
      </div>
      <div className={styles.imageAside}>
        <DeviceIllustration role={role} />
      </div>
    </div>
  );
};

export default Introduction;
